import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a3879a8b7bfa66cce72f3012e50df0b1@o4507493850087424.ingest.us.sentry.io/4507686665781248",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});